export default {
  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage': '错误的用户名或者密码',
  'pages.login.failure': '登录失败，请重试！',
  'pages.login.success': '登录成功！',
  'pages.login.username.placeholder': '用户名: admin or user',
  'pages.login.username.required': '用户名是必填项！',
  'pages.login.password.placeholder': '密码: ant.design',
  'pages.login.password.required': '密码是必填项！',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机号！',
  'pages.login.phoneNumber.required': '手机号是必填项！',
  'pages.login.phoneNumber.invalid': '不合法的手机号！',
  'pages.login.captcha.placeholder': '请输入验证码！',
  'pages.login.captcha.required': '验证码是必填项！',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '自动登录',
  'pages.login.forgotPassword': '忘记密码 ?',
  'pages.login.submit': '登录',
  'pages.login.loginWith': '其他登录方式 :',
  'pages.login.loginWithGoogle': '使用 Google 登录',
  'pages.login.loginWithGithub': '使用 Github 登录',
  'pages.login.loginWithOkta': '使用 Okta 登录',
  'pages.login.loginWith.requesting_auth': '正在请求授权页面',
  'pages.login.loginWith.waiting_auth': '请完成账户授权',
  'pages.login.registerAccount': '注册账户',
  'pages.welcome.advancedComponent': '高级表格',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.advancedLayout': '高级布局',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.AuditList.FileAuditList.files': '个文件',
  'pages.AuditList.FileAuditList.pathTip': '被控设备上的路径',
  'pages.AuditList.FileAuditList.limitedTip': '显示最大的10个文件',
  'pages.AuditList.FileAuditList.exportFilename': '文件传输日志',
  'pages.AuditList.ConnAuditList.modal_title': '确认操作',
  'pages.AuditList.ConnAuditList.diconnect_confirm_tip': '是否确实要断开此设备?',
  'pages.AuditList.ConnAuditList.disconnect_succ': '断开成功!',
  'pages.AuditList.ConnAuditList.disconnect_fail': '断开失败!',
  'pages.AuditList.ConnAuditList.exportFilename': '连接日志',
  'pages.AuditList.AlarmAuditList.Login Device / Local Device': '登录设备 / 主控设备',
  'pages.AuditList.AlarmAuditList.Login Account / Remote Device': '登录账户 / 被控设备',
  'pages.AuditList.AlarmAuditList.Login Account': '登录账户',
  'pages.AuditList.AlarmAuditList.Device ID': '设备ID',
  'pages.AuditList.AlarmAuditList.Device Name': '设备用户名',
  'pages.AuditList.AlarmAuditList.Remote Device': '被控设备',
  'pages.AuditList.AlarmAuditList.Event Time': '发生时间',
  'pages.AuditList.AlarmAuditList.IP whiltelist': '白名单外的IP尝试访问',
  'pages.AuditList.AlarmAuditList.ExceedThirtyAccessAttempts': '连续30次尝试访问',
  'pages.AuditList.AlarmAuditList.MultipleAccessAttemptsWithinOneMinute': '1分钟内多次尝试访问',
  'pages.AuditList.AlarmAuditList.ExceedThirtyLoginAttempts': '连续30次尝试登录',
  'pages.AuditList.AlarmAuditList.MultipleLoginsAttemptsWithinOneMinute': '1分钟内多次尝试登录',
  'pages.AuditList.AlarmAuditList.exportFilename': '风险异常报警日志',
  'pages.AuditList.ConsoleAuditList.Operation': '操作',
  'pages.AuditList.ConsoleAuditList.Name': '名称',
  'pages.AuditList.ConsoleAuditList.Name list': '名称列表',
  'pages.AuditList.ConsoleAuditList.ID list': 'ID列表',
  'pages.AuditList.ConsoleAuditList.Result': '结果',
  'pages.AuditList.ConsoleAuditList.Remote': '被控端',
  'pages.AuditList.ConsoleAuditList.Local': '主控端',
  'pages.AuditList.ConsoleAuditList.User Login': '用户登录控制台',
  'pages.AuditList.ConsoleAuditList.Add Group': '新建群组',
  'pages.AuditList.ConsoleAuditList.Add User': '添加用户',
  'pages.AuditList.ConsoleAuditList.Add Device': '添加设备',
  'pages.AuditList.ConsoleAuditList.Delete Groups': '批量删除群组',
  'pages.AuditList.ConsoleAuditList.Disconnect Device': '断开设备连接',
  'pages.AuditList.ConsoleAuditList.Enable Users': '使能用户',
  'pages.AuditList.ConsoleAuditList.Disable Users': '禁用用户',
  'pages.AuditList.ConsoleAuditList.Enable Devices': '批量使能设备',
  'pages.AuditList.ConsoleAuditList.Disable Devices': '批量禁用设备',
  'pages.AuditList.ConsoleAuditList.Update Group': '更新群组',
  'pages.AuditList.ConsoleAuditList.Update User': '更新用户',
  'pages.AuditList.ConsoleAuditList.Update Device': '更新设备',
  'pages.AuditList.ConsoleAuditList.Group Management': '群组管理',
  'pages.AuditList.ConsoleAuditList.User Management': '用户管理',
  'pages.AuditList.ConsoleAuditList.Device Management': '设备管理',
  'pages.AuditList.ConsoleAuditList.exportFilename': '控制台日志',
  'pages.Settings.Strategy.Strategy list': '策略列表',
  'pages.Settings.Strategy.delete_strategy_tip': '确定要删除该策略吗?',
  'pages.Settings.Strategy.New strategy': '新建策略',
  'pages.Settings.Strategy.Rename strategy': '重命名策略',
  'pages.Settings.Strategy.Duplicate strategy': '复制策略',
  'pages.Settings.Strategy.Edit Devices': '编辑设备',
  'pages.Settings.Strategy.Edit Users': '编辑用户',
  'pages.Settings.Strategy.Rename': '重命名',
  'pages.Settings.Strategy.Duplicate': '复制',
  'pages.Settings.Strategy.Delete': '删除',
  'pages.Settings.Strategy.Groups': '群组',
  'pages.Settings.Strategy.Select All': '全选',
  'pages.Settings.Strategy.Permissions': '权限',
  'pages.Settings.Strategy.Password': '密码',
  'pages.Settings.Strategy.Security': '安全',
  'pages.Settings.Strategy.Other': '其它',
  'pages.Settings.Strategy.IP whitelist': 'IP白名单',
  'pages.Settings.Strategy.Cancel': '取消',
  'pages.Settings.Strategy.Submit': '确定',
  'pages.Settings.Strategy.Edit': '编辑',
  'pages.Settings.Strategy.Custom': '自定义',
  'pages.Settings.Strategy.Full access': '完全访问',
  'pages.Settings.Strategy.Screen share': '仅共享屏幕',
  'pages.Settings.Strategy.Enable keyboard/mouse': '允许控制键盘/鼠标',
  'pages.Settings.Strategy.Enable clipboard': '允许同步剪切板',
  'pages.Settings.Strategy.Enable file transfer': '允许传输文件',
  'pages.Settings.Strategy.Enable audio': '允许传输音频',
  'pages.Settings.Strategy.Enable TCP tunneling': '允许建立TCP隧道',
  'pages.Settings.Strategy.Enable remote restart': '允许远程重启',
  'pages.Settings.Strategy.Enable recording session': '允许录制会话',
  'pages.Settings.Strategy.Enable blocking user input': '允许阻止用户输入',
  'pages.Settings.Strategy.Enable remote configuration modification': '允许远程修改配置',
  'pages.Settings.Strategy.Permission type': '权限类型',
  'pages.Settings.Strategy.Approve mode': '认证方式',
  'pages.Settings.Strategy.Password type': '密码类型',
  'pages.Settings.Strategy.Hide connection management window': '隐藏连接管理窗口',
  'pages.Settings.Strategy.One-time password length': '一次性密码长度',
  'pages.Settings.Strategy.Accept sessions via password': '只允许密码访问',
  'pages.Settings.Strategy.Accept sessions via click': '只允许点击访问',
  'pages.Settings.Strategy.Accept sessions via both': '允许密码或点击访问',
  'pages.Settings.Strategy.Use one-time password': '使用一次性密码',
  'pages.Settings.Strategy.Use permanent password': '使用固定密码',
  'pages.Settings.Strategy.Use both passwords': '同时使用两种密码',
  'pages.Settings.Strategy.Enable RDP session sharing': '允许 RDP 会话共享',
  'pages.Settings.Strategy.Changes of this option take effect only when Windows clients have been installed': '改动仅对已安装的Windows客户端生效',
  'pages.Settings.Strategy.Deny LAN discovery': '拒绝局域网发现',
  'pages.Settings.Strategy.Enable direct IP access': '允许IP直接访问',
  'pages.Settings.Strategy.auto_disconnect_option_tip': '自动关闭不活跃的会话',
  'pages.Settings.Strategy.Remove wallpaper during incoming sessions': '接受会话时移除桌面壁纸',
  'pages.Settings.Strategy.minutes': '分钟',
  'pages.Settings.Strategy.Enable': '启用',
  'pages.Settings.Strategy.Disable': '禁用',
  'pages.Settings.Strategy.disabled':'禁用',
  'pages.Settings.Strategy.Default': '默认策略',
  'pages.Settings.Strategy.Apply changes of this page': '应用本页更改',
  'pages.Settings.Strategy.Invalid IP':'无效 IP',
  'pages.Settings.Strategy.IP Whitelisting':'IP 白名单',
  'pages.Settings.Strategy.Clear':'清空',
  'pages.Settings.Strategy.OK':'确认',
  'pages.Settings.Strategy.whitelist_sep':'可以使用逗号，分号，空格或者换行符作为分隔符',
  'pages.Settings.Strategy.Use IP whitelisting':'只允许白名单上的 IP 访问',
  'pages.Settings.Strategy.whitelist_tip':'只有白名单里的 IP 才能访问本机',
  'pages.Settings.KeyPage.failedToFetchKey': '获取密钥失败。',
  'pages.Settings.KeyPage.keyResetSuccess': '密钥重置成功，请重启hbbr以使更改生效。',
  'pages.Settings.KeyPage.errorResettingKey': '重置密钥时发生错误。',
  'pages.Settings.KeyPage.keyUpdateSuccess': '密钥更新成功，请重启hbbr以使更改生效。',
  'pages.Settings.KeyPage.errorUpdatingKey': '更新密钥时发生错误，请检查密钥',
  'pages.Settings.KeyPage.key': '密钥',
  'pages.Settings.KeyPage.privateKey': '私钥',
  'pages.Settings.KeyPage.publicKey': '公钥',
  'pages.Settings.KeyPage.editKey': '更新密钥',
  'pages.Settings.KeyPage.reset': '重置',
  'pages.Settings.KeyPage.custom': '手动输入',
  'pages.Settings.KeyPage.pleaseInputPrivateKey': '请输入您的私钥！',
  'pages.Settings.License.settingsUpdatedSuccess': '设置成功更新。',
  'pages.Settings.License.licenseCannotBeEmpty': '证书不能为空。',
  'pages.Settings.License.license': '许可证',
  'pages.Settings.Relay.GeoOverrideDescription': '只有当存在多个中继服务器时，才能使用位置覆写功能。',
  'pages.Settings.GeoOverride.ipOrDomain': 'IP/域名',
  'pages.Settings.GeoOverride.latitude': '纬度',
  'pages.Settings.GeoOverride.longitude': '经度',
  'pages.Settings.GeoOverride.deleteSelectedConfirm': '确定要删除选中的位置覆写吗？',
  'pages.Settings.GeoOverride.deleteConfirm': '确定要删除该位置覆写吗？',
  'pages.Settings.GeoOverride.editGeoOverride': '编辑覆写',
  'pages.Settings.GeoOverride.addGeoOverride': '添加覆写',
  'pages.Settings.GeoOverride.ipOrDomainRequired': 'IP/域名不能为空。',
  'pages.Settings.GeoOverride.latitudeRequired': '纬度不能为空。',
  'pages.Settings.GeoOverride.longitudeRequired': '经度不能为空。',
  'pages.Settings.GeoOverride.testGeo': '测试位置',
  'pages.Settings.GeoOverride.geofileFetchFailed': '获取GeoFile信息失败。',
  'pages.Settings.GeoOverride.loadGeoOverrides': '加载覆写',
  'pages.Settings.GeoOverride.geoOverride': '位置覆写',
  'pages.Settings.GeoOverride.title': '位置覆写',
  'pages.Settings.GeoOverride.RelaySettings': '设置',
  'pages.Settings.GeoOverride.RelayServers': '中继服务器',
  'pages.Settings.GeoOverride.AddServer': '添加服务器',
  'pages.Settings.GeoOverride.AlwaysUseRelay': '始终使用中继',
  'pages.Settings.GeoOverride.UpdateServers': '导入修改',
  'pages.Settings.GeoOverride.UpdatedAlwaysUseRelay': '已更新始终使用中继',
  'pages.Settings.GeoOverride.FailedToUpdateAlwaysUseRelay': '更新始终使用中继失败',
  'pages.Settings.GeoOverride.UpdatedRelayServers': '已更新中继服务器',
  'pages.Settings.GeoOverride.FailedToUpdateRelayServers': '更新中继服务器失败',
  'pages.Settings.GeoOverride.UpdatedChangeIDSetting': '已更新更改ID设置',
  'pages.Settings.GeoOverride.FailedToUpdateChangeIDSetting': '更新更改ID设置失败',
  "pages.Settings.Others.Session expire time (Day)": "Session 过期时间(天)",
  "pages.Settings.Others.Allow change ID": "允许更改ID",
  "pages.Settings.Others.Disable new devices": "禁用新设备",
  'pages.Settings.OIDC.New Auth Provider': '新增 auth provider',
  'pages.Settings.OIDC.Maximum Number of Authorized Users': '最多认证用户数',
  'pages.Settings.OIDC.MaxAuthCountTooptip': '使用 OIDC 进行身份验证的最大并发用户会话数, 应大于 0。',
  'pages.Settings.OIDC.emptyPrompt': '当前没有第三方认证提供者，请添加一个以使用此功能。',
  'pages.Settings.OIDC.confirm-delete-title': '确认删除',
  'pages.Settings.OIDC.confirm-delete-content': '相关的第三方授权用户无法再登录。您可以进入用户管理界面删除这些用户。',
  'pages.Welcome.License Type': '许可证类型',
  'pages.Welcome.Not set': '未设置',
  'pages.Welcome.Invalid': '无效',
  'pages.Welcome.Demo': '试用',
  'pages.Welcome.Personal': '个人',
  'pages.Welcome.Business': '商业',
  'pages.Welcome.Unknown': '其它',
  'pages.Welcome.Max Peers': '最大设备数',
  'pages.Welcome.RustDesk Server Status': 'RustDesk 服务器状态',
  'pages.Welcome.License Expire Time': '许可证到期时间',
  'pages.Welcome.Document': '文档',
};
